.flutaro-menu-bar {
  background-color: #777 !important;  
}

// needed for visible toolbar/menu on start 
#masthead {
  overflow: unset;
  width: 100vw;
}

.flutaro-menu-bar-pages {
  background: transparent;
}

.flutaro-form-input {
  width: 400px;
  margin-left: 10px;
  margin-right: 10px;
}

.flutaro-form-textarea {
  margin-bottom: 15px;
  width: 100%;
  min-height: 128px;
  margin-left: 10px;
  margin-right: 10px;
}

.box-container {
  height: 0px !important;
}

@media(max-width: 959px) {
  .box-container {
    height: 100% !important;
  }

  .flutaro-form-input,
  .flutaro-form-textarea {
    width: calc(100% - 20px);
  }
  .menu-smart > li > a > div > div > div.btn {
    width: 100%;
  }
  .menu-smart > li > a > div > div > div.btn {
    display: table;
    width: 100%;
  }
  .menu-smart > li > a > div > div > div.btn span.flutaro-test-button-span {
    top: 0px !important;
    display: table-cell;
    vertical-align: middle;
  }
  .menu-wrapper.menu-sticky .main-menu-container, .menu-wrapper.menu-hide-only .main-menu-container {
    overflow: hidden !important;
  }
  .icon-box-icon {
    display: table !important;
    margin: 0 auto !important;
  }
  .icon-box-left .icon-box-content:not(.add-margin) p {
    font-size: 12px !important;
    margin-top: 30px !important;
  }
}

@media(min-width: 959px) {
  .menu-smart, .menu-smart ul, .menu-smart li {
    display: inline-flex !important;
    top: 20px;
    position: relative;
  }
  li.flutaroTestButton {
    position: relative !important;
    top: 10px !important;
  }

}

.flutaroSendButton {
  margin-bottom: 15px !important;
}

.flutaro-try-now-button {
  width: 150px;
  height: 15px;
}

.flutaro-test-button-li {
  height: 38px;
}

.btn .fa, .btn-link .fa {
  top: 3px;
}

.flutaroMapHeader {
  background-image: url(/assets/images/flutaro/FlutaroMapHeader.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.flutaroHeader {
  background-image: url(/assets/images/flutaro/FlutaroHeader.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

#cookie-notice {
  z-index: 1;
  padding: 10px;
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: calc(100%);
  background: #222;
  color: rgba(255, 255, 255, 0.8);
}

#cookie-notice a {
  height: 22px;
  display: inline-block;
  cursor: pointer;
  margin-left: 0.5rem;
  color: #000;
}

#cookie-notice a.ok {
  background: #505759;
  color: #fff
}

#cookie-notice a.ok:hover {
  background: #2b2f30
}

#cookie-notice a span {
  position: relative;
  top: -6px;
}

#cookie-notice a.more {
  color: #c5ece6
}

.cookie-notice-container {
  height: 22px
}

@media (max-width: 767px) {
  #cookie-notice span {
    padding-top: 3px;
    margin-bottom: 1rem;
    font-size: 0.7rem;
  }
  #cookie-notice a {
    position: relative;
    bottom: 4px;
  }
  #cookie-notice a.more {
    visibility: hidden;
  }
  .cookie-notice-container {
    height: 30px;
  }
}

.box-wrapper {
  display: block;
  width: 100%;
  // height: 100%;
  table-layout: fixed;
  position: relative;
}


.menu-with-logo .menu-smart > li, .menu-with-logo .menu-smart > li > a {
  height: auto !important;
}

.copy {
  position: absolute !important;
  width: 100% !important;
  margin-top: 15px;
}

.site-info-social {
  width: 100%!important;
  text-align: center!important;
}


footer .main-container .row-inner > div {
  padding:0px;
}
